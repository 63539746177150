<template>
    <div v-if="info" class="feed-item-container new-invitee">
        <div class="title-feed">
            <h5>Praising God for our new member!</h5>
            <p class="hour-container">
                {{ timeAgo }}
            </p>
        </div>
        <div class="feed-item-content new-user-feed">
            <div class="row no-gutters">
                <div class="col-md-3">
                    <div class="img-container">
                        <img :src="info.avatar || require('../../assets/img/feeds/user_img_placeholder.png')" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="info-container">
                        <p v-if="info.is_church" class="user-name">
                            {{ info.church_name }}
                        </p>
                        <p v-else class="user-name">
                            {{ info.fname }} {{ info.lname }}
                        </p>
                        <p v-if="info.is_church" class="user-church">
                            {{ info.fname }} {{ info.lname }}
                        </p>
                        <p v-else class="user-church">
                            Discovery {{ info.church_name }}
                        </p>
                        <p class="user-mem-stat">
                            {{ info.is_church ? 'Church Member' : 'Member' }}
                        </p>
                        <div class="btn-container">
                            <button type="button" class="btn btn-send-msg">Send Message</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else />
</template>

<script>
import { defineComponent } from 'vue';
import moment from 'moment';

export default defineComponent({
    props: {
        info: Object,
        date: String
    },
    computed: {
        timeAgo() {
            return moment(this.date).fromNow();
        }
    }
})
</script>
