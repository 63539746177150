<template>
    <div class="feed-item-container new-article-church">
        <div class="title-feed">
        <h5>New Article from your church</h5>
        <p class="hour-container">
            1hr ago
        </p>
        </div>
        <div class="feed-item-content new-article-feed">
        <div class="row no-gutters">
            <div class="col-md-3">
            <div class="img-container">
                <img :src="require('../../assets/img/feeds/newarticle.png')" alt="" class="img-fluid">
            </div>
            </div>
            <div class="col-md-9">
            <div class="info-container">
                <p class="article-title">
                Title
                </p>
                <p class="article-description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis var...
                <a href="">Show more</a>
                </p>
                <div class="btn-container">
                <button type="button" class="btn btn-send-msg">Send Message</button>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>