<template>
    <div v-if="display" class="feed-item-container new-invitee">
        <div class="title-feed">
        <h5>Welcome to our new invitee!</h5>
            <p class="hour-container">
                {{ timeAgo }}
            </p>
        </div>
        <div class="feed-item-content new-user-feed">
            <div class="row no-gutters">
                <div class="col-md-3">
                    <div class="img-container">
                        <img :src="info.avatar || require('../../assets/img/feeds/user_img_placeholder.png')" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="info-container">
                        <p v-if="info.is_church === 1" class="user-name">
                            {{ info.church_name }}
                        </p>
                        <p v-else class="user-name">
                            {{ info.fname }} {{ info.lname }}
                        </p>
                        <p v-if="info.is_church === 1" class="user-church">
                            {{ info.fname }} {{ info.lname }}
                        </p>
                        <p v-else class="user-church">
                            {{ info.church_name }}
                        </p>
                        <p class="user-mem-stat">
                            {{ info.is_church === 1 ? 'Church Member' : 'Member' }}
                        </p>
                        <div class="btn-container">
                            <button type="button" class="btn btn-send-msg disabled">Send Message</button>
                            <button type="button" class="btn btn-confirm-mem" @click="confirmUser">Confirm Membership</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else />
</template>

<script>
import moment from 'moment';
import { useFind } from '@feathersjs/vuex';
import { computed, defineComponent, getCurrentInstance/*, ref*/ } from 'vue';
import { mapActions } from 'vuex';

export default defineComponent({
    props: {
        info: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    setup(props) {
        const vm = getCurrentInstance();
        const { Church, UserVerified }  = vm.appContext.provides.$FeathersVuex.api;
        const user = vm.appContext.provides.store.state.auth.user;
        const churchData = useFind({ model: Church, params: computed(() => {
            return { query: { owner_id: user && user.id || 0 } }
        }), immediate: true });
        const isChurch = computed(() => {
            if(user)
                return churchData.items.value.length !== 0;
            return false;
        });

        const verifiedLists = useFind({
            model: UserVerified,
            params: computed(() => {
                return { query: { user_id: props['info'].id } }
            }),
            immediate: true 
        });
        
        const display = computed(() => {
            if(verifiedLists.isPending.value) {
                return false;
            }
            if(props['info'].is_church && !isChurch.value) {
                return false;
            }
            return verifiedLists.items.value.length < 3 &&
                verifiedLists.items.value.filter(
                    item => item.member_id === user.id
                ).length === 0;
        });

        return {
            display,
            churchData: churchData.items,
            verifiedLists: verifiedLists.items
        };
    },
    computed: {
        timeAgo() {
            return moment(this.info.createdAt).fromNow();
        }
    },
    methods: {
        async confirmUser() {
            await this.apiPatch([
                this.info.is_church === 1 ? 'confirm-church' : 'confirm-user',
                {
                    id: this.info.id
                }
            ]);
        },
        ...mapActions('user-church', { updateChurchMember: 'patch' }),
        ...mapActions('apis', { apiPatch: 'patch' })
    }
})
</script>
