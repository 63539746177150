<template>
    <div class="feed-item-container new-article">
        <div class="title-feed">
        <button type="button" class="btn btn-add-bag">Add to Bag</button>
        <h5>Checkout our new article in circulation</h5>
        <p class="hour-container">
            1hr ago
        </p>
        </div>
        <div class="feed-item-content">
        <div class="row no-gutters">
            <div class="col-md-3">
            <div class="img-container">
                <img :src="require('../../assets/img/feeds/samplearticle.png')" alt="" class="img-fluid">
            </div>
            </div>
            <div class="col-md-9">
            <div class="info-container">
                <p class="article-name">
                Bookname
                </p>
                <p class="article-author">
                Author : John Bevere
                </p>
                <p class="article-shared">
                Shared by : Pastor
                </p>
                <p class="article-description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam.
                </p>
                <div class="article-stats">
                <ul>
                    <li class="count-like">22 Likes</li>
                    <li class="count-review">33 Review</li>
                    <li class="count-share">44 Share</li>
                </ul>
                </div>
            </div>
            </div>
        </div>
        </div>
        <div class="feeds-react-container">
        <div class="row no-gutters">
            <div class="col-4 d-grid">
            <button class="btn btn-like" type="submit"> <img :src="require('../../assets/img/feeds/like.svg')" alt="" class="img-fluid"> Like</button>
            </div>
            <div class="col-4 d-grid">
            <button class="btn btn-review" type="submit"> <img :src="require('../../assets/img/feeds/review.svg')" alt="" class="img-fluid"> Review</button>
            </div>
            <div class="col-4 d-grid">
            <button class="btn btn-share" type="submit"> <img :src="require('../../assets/img/feeds/share.svg')" alt="" class="img-fluid"> Share</button>
            </div>
        </div>
        </div>
    </div>
</template>