<template>
    <section class="newsfeed-section">
        <div class="container my-container">
            <div class="newsfeed-content">
                <div class="row no-gutters">
                    <div class="col-md-3">
                        <SideMenu />
                    </div>
                    <div class="col-md-9">
                        <InvitationWidget v-for="(item, index) in unverifiedUsers" :key="index" :info="item" />
                        <!-- <InvitationWidget v-for="(item, index) in newMembers" :key="index" :info="item" />

                        <template v-if="isChurch">
                            <InvitationWidget v-for="(item, index) in churchUnconfirmed" :key="index" :info="item" church />
                        </template> -->
                        
                        <template v-for="(item, index) in feeds">
                            <ArticleReviewFeedback v-if="item.type === 'ArticleReview'" :key="index" :info="item.info" :date="item.createdAt" />
                            <NewArticleFeedback v-if="item.type === 'NewArticle'" :key="index" :info="item.info" :date="item.createdAt" />
                            <NewMemberFeedback v-if="item.type === 'NewMember'" :key="index" :info="item.info" :date="item.createdAt" />
                            <NewArticleChurch v-if="item.type === 'NewChurchArticle'" :key="index" :info="item.info" :date="item.createdAt" />
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { computed, defineComponent, getCurrentInstance } from 'vue';
import SideMenu from '../components/utils/side.menu.vue';
import InvitationWidget from '../components/utils/invitation.widget.vue';
import ArticleReviewFeedback from '../components/newsfeed.components/ArticleReviewFeedback.vue';
import NewArticleFeedback from '../components/newsfeed.components/NewArticleFeedback.vue';
import NewMemberFeedback from '../components/newsfeed.components/NewMemberFeedback.vue';
import NewArticleChurch from '../components/newsfeed.components/NewArticleChurchFeedback.vue';
import { useFind } from '@feathersjs/vuex';

export default defineComponent({
    setup() {
        const vm = getCurrentInstance();

        const user = vm.appContext.provides.store.state.auth.user;
        const { ChurchFeedback, Church, VWUnverifiedUsers }  = vm.appContext.provides.$FeathersVuex.api;

         const feeds = useFind({ model: ChurchFeedback, params: computed(() => {
            return { query: { $sort: { id: -1 } } };
        }), fetchParams: computed(() => {
            return { query: { $sort: { id: -1 } } };
        }), immediate: true });
        
        const churchData = useFind({ model: Church, params: computed(() => {
            return { query: { owner_id: user && user.id || 0 } }
        }), immediate: true });

        const unverifiedUsers = useFind({
            model: VWUnverifiedUsers,
            params: computed(() => {
                return { query: { id: { $nin: [ user.id ] } } };
            }),
            fetchParams: computed(() => {
                return { query: { id: { $nin: [ user.id ] } } };
            })
        });

        return {
            feeds: feeds.items,
            unverifiedUsers: unverifiedUsers.items,
            isChurch: computed(() => {
                if(user)
                    return churchData.items.value.length !== 0;
                return false;
            })
        };
    },
    components: {
        SideMenu,
        InvitationWidget,
        ArticleReviewFeedback,
        NewArticleFeedback,
        NewMemberFeedback,
        NewArticleChurch
    }
})
</script>
