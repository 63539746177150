<template>
<div class="feeds-list position-sticky">
    <ul>
        <li class="feed-item">
            <router-link to="/my-articles">My Articles</router-link>
        </li>
        <li class="feed-item">
            <router-link to="/article-i-follow">Articles I follow</router-link>
        </li>
        <li class="feed-item">
            <router-link to="/my-reviews">My Reviews</router-link>
        </li>
        <li class="feed-item">
            <router-link to="/my-likes">My Likes</router-link>
        </li>
        <li v-if="isChurch" class="feed-item">
            <router-link to="/church-members">Church Members</router-link>
        </li>
    </ul>
</div>
</template>

<script>
import { useFind } from '@feathersjs/vuex';
import { computed, defineComponent, getCurrentInstance } from 'vue';
import { mapState } from 'vuex';

export default defineComponent({
    computed: {
        ...mapState('auth', ['user'])
    },
    setup() {
        const vm = getCurrentInstance();
        const { Church }  = vm.appContext.provides.$FeathersVuex.api;
        const user = computed(() => vm.appContext.provides.store.state.auth.user);
        const churchData = useFind({ model: Church, params: computed(() => {
            return { query: { owner_id: user.value && user.value.id || 0 } }
        }), immediate: true });
        return {
            isChurch: computed(() => {
                const user = vm.appContext.provides.store.state.auth.user;
                if(user)
                    return churchData.items.value.length !== 0;
                return false;
            })
        };
    }
})
</script>

<style scoped>
.feeds-list.position-sticky a {
    color: #212529;
    text-decoration: none;
}
</style>